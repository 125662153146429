<template>
  <v-col
    cols="12"
    md="12"
    :class="{'d-flex' : emptyList}"
  >
    <v-row align="center">
      <v-col cols="12">
        <v-item-group active-class="primary">
          <v-container :class="{'d-flex justify-center' : emptyList}">
            <template v-if="hasTodayData || hasFutureData">
              <section>
                <v-row>
                  <v-col><h6>Confirmed Appointments (Today)</h6></v-col>
                </v-row>
                <AppointmentCard
                  v-if="hasTodayData"
                  :appointments="$store.state.appointments.list.now"
                />
              </section>
              <section>
                <v-row>
                  <v-col><h6>Confirmed Appointments (Upcoming)</h6></v-col>
                </v-row>
                <AppointmentCard
                  v-if="hasFutureData"
                  :appointments="$store.state.appointments.list.future"
                />
              </section>
            </template>
            <template v-else>
              <div
                class="text-uppercase p-r-5 m-r-5"
                style="border-right: 2px solid #212121;"
              >
                No Appointment Found
              </div>
              <div class="text-uppercase">
                <router-link :to="{name: 'NewAppointment'}">
                  create new appointment?
                </router-link>
              </div>
            </template>
          </v-container>
        </v-item-group>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import AppointmentCard from "@/components/AppointmentCard";

export default {
    name: "ConfirmAppointments",
    components: {AppointmentCard},
    data: () => ({
        hasTodayData: false,
        hasFutureData: false,
        emptyList: false
    }),
    mounted() {
        this.getAppointments();
        window.vueEvent.$emit('reloadHeader');
    },
    methods: {
        async getAppointments() {
            try {
                await this.$store.dispatch('appointments/get');
                this.hasFutureData = this.$store.state.appointments.list.future.length > 0;
                this.hasTodayData = this.$store.state.appointments.list.now.length > 0;
                this.emptyList = !this.hasTodayData && !this.hasFutureData;
            } catch (exception) {
                console.log(exception)
            }
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
    }
}
</style>