<template>
  <v-row>
    <v-col
      v-for="(appointment, index) in appointments"
      :key="index"
      cols="12"
      md="5"
      sm="4"
    >
      <v-item>
        <v-card
          height="auto"
          color="#f3f5f7"
          @click="$router.push( {name: 'PatientCaseHistory', params: {appointmentId: appointment.id, id: appointment.patientId}, query: { step: '1'} } )"
        >
          <v-list
            nav
            color="transparent"
          >
            <div class="patient-image">
              <img
                :src="appointment.patientImage"
                :alt="appointment.patientName"
              >
            </div>
            <v-list-item-group class="mt-4">
              <v-list-item style="min-height: auto;pointer-events: none;">
                <v-list-item-content class="pt-0 pb-0 text-sm-body-2">
                  {{ appointment.patientName }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: auto;pointer-events: none;">
                <v-list-item-content class="pt-0 pb-0 text-sm-body-2">
                  Appointment @ {{ moment(appointment.scheduledAt).format('DD MMMM, YYYY') }}
                </v-list-item-content>
              </v-list-item>
              <v-chip
                class="ma-2 text-capitalize caption"
                style="height: 25px;"
                color="orange"
                outlined
              >
                {{ appointment.status }}
              </v-chip>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-item>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "AppointmentCard",
    props: {
        appointments: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style scoped>
    img {
        max-width: 100%;
        max-height: 100%;
    }

    .patient-image {
        height: 150px;
        text-align: center;
    }
</style>